<template>
  <div>
      <v-main>
        <v-container fluid>
            <v-card>
              <v-card-title>
                <v-col cols="12" md="6" align="left" class="pa-0">
                  Device Info
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="listDevice"
                :options.sync="pagination"
                :loading=loading
                :footer-props="footerProps"
                :server-items-length="notifDevice"
              >
              </v-data-table>
            </v-card>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{msgSnack}} 
            </v-snackbar>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  components: {
    OverlayProgress
  },
  data() {
    return {
        pagination: {
          page: 1,
          itemsPerPage: 20,
        },
        footerProps: {
          itemsPerPageOptions: [20, 50, 100, -1],
          showFirstLastPage: true,
          showCurrentPage: true,
        },
        loading: true,
        notifDevice: 0,
        headers: [
          {
            text: 'Android ID',
            align: 'start',
            sortable: false,
            value: 'androidId',
          },
          { text: 'Version SDK', sortable: false, value: 'versionSdkint' },
          { text: 'Brand', sortable: false, value: 'brand' },
          { text: 'Model', sortable: false, value: 'model' },
          { text: 'Supported abis', sortable: false, value: 'supportedabis' },
        ],
        listDevice: [],
        snackbar: false,
        timeout: 4000,
        msgSnack: ''
    }
  },
  watch:{
    pagination:{
      handler(){
        this.fetchNotif()
      },
      deep:true
    }
  },
  methods:{
    async fetchNotif(){
      this.loading = true
      const {page, itemsPerPage} = this.pagination
      var url = this.$root.api.get.member
      if (itemsPerPage > -1) {
        url = this.$root.api.get.device+'?start='+(page-1)+'&limit='+itemsPerPage
      }
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: url
      }).then((res) => {
          this.notifDevice = res.data.count
          this.listDevice = res.data.list
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false 
      this.loading = false
    },
  },
  mounted() {
    this.fetchNotif()
  }
}
</script>
<style scoped>
</style>
